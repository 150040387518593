// kendo 스타일적용
import '@progress/kendo-theme-default/dist/all.css';
import { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login/Login';
import Main from './pages/Main/Main';
import Admin_License from './pages/Admin/Admin_License';
import Test from './pages/Test/Test';
// import Test_Main from './pages/Test.tsx/Test_Main';
import User_License from './pages/User/User_License';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
// import User_Contact from './pages/User/User_Contact';
// import Admin_License_User_Management from './pages/Admin/Admin_License_User_Management';
import Master_License_Management from './pages/Master_Admin/Master_License_Management';
import Master_Login from './pages/Login/Master_Login';

const App = () => {
  // 솔루션 관리자 마스터 로그인
  const [isMasterLoggedIn, setIsMasterLoggedIn] = useState(false);
  // 로그인 상태 관리
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // 로그인 유저 정보 관리
  const [MasterInfo, setMasterInfo] = useState('');
  const [userInfo, setUserInfo] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    // 컴포넌트가 마운트될 때 로컬 스토리지에서 로그인 상태를 확인
    //@ts-ignore
    const loggedIn = sessionStorage.getItem('isMasterLoggedIn');
    // if (loggedIn === 'true') {
    //   setIsLoggedIn(true);
    // }
    // 상태를 초기화하고 확인된 로그인 상태를 설정
    setIsMasterLoggedIn(loggedIn === 'true');
  }, []);


  useEffect(() => {
    // 컴포넌트가 마운트될 때 로컬 스토리지에서 로그인 상태를 확인
    //@ts-ignore
    const loggedIn = sessionStorage.getItem('isLoggedIn');
    // if (loggedIn === 'true') {
    //   setIsLoggedIn(true);
    // }
    // 상태를 초기화하고 확인된 로그인 상태를 설정
    setIsLoggedIn(loggedIn === 'true');
  }, []);

  // 로그인 관리 세션스토리지
  const handleLogin = (user: string) => {
    // 실제 로그인 로직을 구현할 부분
    console.log('user:', user);
    //@ts-ignore
    // 유저 정보 넣기
    sessionStorage.setItem('isLoggedIn', 'true');
    //@ts-ignore
    delete user.uLoginPw;
    const userInfo = JSON.stringify(user)
    //@ts-ignore
    sessionStorage.setItem('userInfo', userInfo);
    setIsLoggedIn(true);
    setUserInfo(userInfo);

  };
  // 정보창 닫기
  const closeModal = () => {
    setIsModalOpen(false);
  };
  // useEffect(() => {
  //   handleLogin('')
  // }, []);

  // 로그인 관리 세션스토리지
  const handleMaster_Login = (Master: string) => {
    // 실제 로그인 로직을 구현할 부분
    console.log('Master:', Master);
    //@ts-ignore
    // 유저 정보 넣기
    sessionStorage.setItem('isMasterLoggedIn', 'true');
    //@ts-ignore
    delete Master.caLoginPw;
    const MasterInfo = JSON.stringify(Master)
    //@ts-ignore
    sessionStorage.setItem('MasterInfo', MasterInfo);
    setIsMasterLoggedIn(true);
    setMasterInfo(MasterInfo);

  };
  

  return (
    <Routes>
      {/* 최고관리자 로그인페이지*/}
      <Route path="/master" element={<Navigate to="/master_license_management" />} />
      {/* 최고관리자 페이지*/}
      <Route
        path='/master_license_management'
        // element={isLoggedIn ? <Master_License_Management /> : <Login onLogin={handleLogin} />}
        element={isMasterLoggedIn ? <Master_License_Management /> : <Master_Login onMaster_Login={handleMaster_Login} />}
      />
      {/* <Route path="/" element={<Login onLogin={handleLogin} />} /> */}
      <Route path="/" element={<Main />} />
      <Route path='/main' element={<Main />} />
      {/* <Route path="/login" element={<Navigate to="/" />} /> */}
      <Route path="/login" 
        element={isLoggedIn ? <Main /> : <Login onLogin={handleLogin} />}
      />
      {/* 비밀번호찾기 */}
      <Route
        path='/forget_password'
        element={<ForgotPassword onClose={closeModal} />}
      />
      {/* 고객사 유저 페이지 */}
      <Route
        path="/user_license"
        element={isLoggedIn ? <User_License /> : <Login onLogin={handleLogin} />}
      />
      {/* 고객사 관리자 페이지 */}
      <Route
        path='/admin_license'
        // element={isMasterLoggedIn ? <Admin_License /> : (isLoggedIn) ? <Admin_License /> : <Login onLogin={handleLogin} />}
        // @ts-ignore
        element={isMasterLoggedIn ? <Admin_License /> : (isLoggedIn && JSON.parse(sessionStorage.getItem('userInfo'))?.uAdmin === "관리") ? <Admin_License /> : <Login onLogin={handleLogin} />}
        // element={isLoggedIn ? <Admin_License /> : <Login onLogin={handleLogin} />}
      />
      {/* 없는 URL 접근시 출력하는 페이지 */}
      <Route path='*' element={<Navigate to="/" />} />
      {/* 테스트 페이지 */}
      <Route path='/test' element={<Test />} />


      {/* 고객사관리자 유저관리페이지 */}
      {/* <Route
        path='/admin_license_user_management'
        element={isLoggedIn ? <Admin_License_User_Management /> : <Login onLogin={handleLogin} />}
      /> */}
      {/* 고객사 문의하기페이지 */}
      {/* <Route
        path='/user_contact'
        element={isLoggedIn ? <User_Contact /> : <Login onLogin={handleLogin} />}
      /> */}
      {/* <Route
        path="/test"
        element={isLoggedIn ? <Test /> : <Login onLogin={handleLogin} />}
      /> */}
    </Routes>
  );
}

export default App;
