import React, { useEffect, useState, useRef, ForwardedRef, forwardRef, ChangeEvent } from 'react'
import { clientConfig } from '../api/clientConfig'
import axios from 'axios';
import { getUserInfoFromStorage } from '../utils/userInfoModule';
import Popup from './Popup/Popup';
import { ComboBox, DropDownList } from '@progress/kendo-react-dropdowns';

interface Menu {
  mId: string;
  pdId: string;
  mName: string;
  mUrl: string;
  mType: string;
  mImageUrl: string;
  msId: string;
  msName: string;
  msUrl: string;
  msTarget: string;
}

// 유저 데이터 타입 정의
interface EditUserInfo {
  caId: string,
  caLoginId: string,
  caLoginPw: string,
  caName: string,
  caEmail: string,
  caPhone: string,
  caDept: string,
  caRank: string,
  caRegDate: string,
}

// 등록유저 타입 정의
interface AddUserInfo {
  caId: string,
  caLoginId: string,
  caLoginPw: string,
  caName: string,
  caEmail: string,
  caPhone: string,
  caDept: string,
  caRank: string,
  caRegDate: string,
}

interface MasterSolutionHeaderProps {
  openMenu2: boolean;
  handleMenu2: () => void;
  companyInfo2: any; // 받아올 회사 정보를 담을 props

}

// const MasterSolutionHeader = ({ }) => {
const MasterSolutionHeader: React.FC<MasterSolutionHeaderProps> = ({ openMenu2, handleMenu2, companyInfo2 }) => {
  // 위젯창 보여주기 상태 관리
  const [isSideMenuVisible, setSideMenuVisible] = useState(false);
  // console.log('MasterSolutionHeader : ', openMenu);
  console.log('MasterSolutionHeader : ', companyInfo2);

  useEffect(() => {
    setSideMenuVisible(openMenu2);
  }, [openMenu2]);
  // 위젯창의 X버튼 눌러서 위젯창 닫기
  const closeSideMenu = () => {
    setSideMenuVisible(false);
    handleMenu2()
    console.log('toggleSideMenu 닫기 동작!');
  };


  const [menuData, setMenuData] = useState<Array<Menu>>([]);
  // 고객센터 매뉴 상태 관리
  const [menuData2, setMenuData2] = useState<Array<Menu>>([]);
  // 검색창 보여주기 상태 관리
  const [isSearchVisible, setSearchVisible] = useState(false);
  // DB 유저정보 관리



  // 유저 수정 정보
  const [editUser, setEditUser] = useState<EditUserInfo>({
    caId: '',
    caLoginId: '',
    caLoginPw: '',
    caName: '',
    caEmail: '',
    caPhone: '',
    caDept: '',
    caRank: '',
    caRegDate: '',
  })

  // 유저 등록 정보
  const [addUser, setAddUser] = useState<AddUserInfo>({
    caId: '',
    caLoginId: '',
    caLoginPw: '',
    caName: '',
    caEmail: '',
    caPhone: '',
    caDept: '',
    caRank: '',
    caRegDate: '',
  })

  // 로그아웃 버튼 클릭 시 sessionStorage에서 isLoggedIn 정보 삭제
  const handleLogout = () => {
    //@ts-ignore
    sessionStorage.clear();
    window.location.reload();
    console.log('로그아웃');
  };


  // 검색창 열고 닫기
  const toggleSearch = () => {
    setSearchVisible(!isSearchVisible);
    console.log('toggleSearch 동작!');
  };
  // 위젯창 열고 닫기
  const toggleSideMenu = () => {
    setSideMenuVisible(true);
    handleMenu2()
    setEditUser({
      ...editUser,
      caId: companyInfo2?.caId,
      caLoginId: companyInfo2?.caLoginId,
      caLoginPw: companyInfo2?.caLoginPw,
      caName: companyInfo2?.caName,
      caEmail: companyInfo2?.caEmail,
      caPhone: companyInfo2?.caPhone,
      caDept: companyInfo2?.caDept,
      caRank: companyInfo2?.caRank,
      caRegDate: companyInfo2?.caRegDate,
    });
  }
  // 세선스토리지에서 정보가져오기
  const userInfoObject = getUserInfoFromStorage();
  // 팝업창 상태 관리
  const [showPopup, setShowPopup] = useState(false);
  // 팝업창 메세지 관리
  const [popupMessage, setPopupMessage] = useState('');
  // 팝업창 닫기 관리
  const closePopup = () => {
    setShowPopup(false);
  };
  // PRODUCT 메뉴 DB조회
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`${clientConfig.URL}/getMenu`, {
        })
        if (response.data.length > 0) {
          setMenuData(response.data); // 서버에서 받아온 데이터를 상태로 설정
        }
      } catch (error) {
        console.error('Error Main_PRODUCT fetchData:', error);
      }
    };

    fetchData();
  }, []);

  // 고객센터 메뉴 DB조회
  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const response = await axios.post(`${clientConfig.URL}/getMenu2`, {
        })
        if (response.data.length > 0) {
          setMenuData2(response.data); // 서버에서 받아온 데이터를 상태로 설정
        }
      } catch (error) {
        console.error('Error Main_고객센터 fetchData:', error);
      }
    };

    fetchData2();
  }, []);

  useEffect(() => {
    // 권한 가져오기
    setEditUser({
      ...editUser,
      caId: companyInfo2?.caId,
      caLoginId: companyInfo2?.caLoginId,
      caLoginPw: companyInfo2?.caLoginPw,
      caName: companyInfo2?.caName,
      caEmail: companyInfo2?.caEmail,
      caPhone: companyInfo2?.caPhone,
      caDept: companyInfo2?.caDept,
      caRank: companyInfo2?.caRank,
      caRegDate: companyInfo2?.caRegDate,
    });
  }, [companyInfo2])


  // 유저 정보 수정값 관리
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    // console.log('handleChange : ' , e);
    // console.log('handleChangetypeof : ' , typeof e);
    setEditUser({
      ...editUser,
      [field]: e.target.value,
    });
  };
  console.log(editUser);
  // 유저 정보 등록값 관리
  const handleUserAdd = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    // console.log('handleChange : ' , e);
    // console.log('handleChangetypeof : ' , typeof e);
    setAddUser({
      ...addUser,
      [field]: e.target.value,
    });
  };
  // 유저 정보 업데이트
  const handelUpdateUserInfo = async () => {
    // console.log('handelUpdateUserInfo동작 : ', handelUpdateUserInfo);
    if (!userInfoObject) {
      setPopupMessage("올바른 접근이 아닙니다!");
      setShowPopup(true);
      return false;
    }
    else if (!editUser.caEmail) {
      setPopupMessage("이메일이 없습니다.");
      setShowPopup(true);
      return false;
    }
    else if (!editUser.caLoginId) {
      setPopupMessage("아이디가 없습니다.");
      setShowPopup(true);
      return false;
    }
    else if (!editUser.caName) {
      setPopupMessage("이름이 없습니다.");
      setShowPopup(true);
      return false;
    }
    else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(editUser.caEmail)) {
      setPopupMessage("올바른 이메일 형식이 아닙니다.");
      setShowPopup(true);
      return false;
    }
    else {

      try {
        const response = await axios.post(`${clientConfig.URL}/updateMasterUserInfo`, {
          caId: editUser?.caId,
          caLoginId: editUser.caLoginId,
          caName: editUser.caName,
          caEmail: editUser.caEmail,
          caPhone: editUser.caPhone,
          caDept: editUser.caDept,
          caRank: editUser.caRank,
        })
        // console.log('response : ', response);
        if (response.status === 200) {
          // 성공적으로 업데이트되면 팝업 메시지 설정
          setPopupMessage("수정되었습니다.");
          setShowPopup(true);
        } else {
          setPopupMessage("수정 실패.");
          setShowPopup(true);
        }
      } catch (error: any) {
        console.error('updateMasterUserInfo / error :', error);
        if (error.response && error.response.status === 400) {
          setPopupMessage("아이디가 중복됩니다.");
          setShowPopup(true);
          // setPopupMessage(error.response.data.error);
          // setShowPopup(true);
        } else {
          setPopupMessage("수정 중 서버오류가 발생했습니다.");
          setShowPopup(true);
        }
      }
    }
  }

  // 유저등록 요청
  const handelInsertUserInfo = async () => {
    console.log('addUseraddUser', addUser);

    if (!userInfoObject) {
      setPopupMessage("올바른 접근이 아닙니다!");
      setShowPopup(true);
      return false;
    }
    else if (!addUser.caEmail) {
      setPopupMessage("이메일이 없습니다.");
      setShowPopup(true);
      return false;
    }
    else if (!addUser.caLoginId) {
      setPopupMessage("아이디가 없습니다.");
      setShowPopup(true);
      return false;
    }
    else if (!addUser.caName) {
      setPopupMessage("이름이 없습니다.");
      setShowPopup(true);
      return false;
    }
    else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(addUser.caEmail)) {
      setPopupMessage("올바른 이메일 형식이 아닙니다.");
      setShowPopup(true);
      return false;
    }
    else {

      try {
        const response = await axios.post(`${clientConfig.URL}/insertMasterUserInfo`, {
          caLoginId: addUser.caLoginId,
          caName: addUser.caName,
          caEmail: addUser.caEmail,
          caPhone: addUser.caPhone,
          caDept: addUser.caDept,
          caRank: addUser.caRank,
        })
        console.log('response : ', response);
        if (response.status === 200) {
          // 성공적으로 업데이트되면 팝업 메시지 설정
          setPopupMessage("등록 되었습니다. 임시 비밀번호는 이메일로 발송됩니다");
          setShowPopup(true);
          // 임시비밀번호 이메일 발송
          handleForgotPassword()
        } else {
          setPopupMessage("등록 실패.");
          setShowPopup(true);
        }
      } catch (error: any) {
        console.error('insertMasterUserInfo / error :', error);
        if (error.response && error.response.status === 400) {
          setPopupMessage("아이디가 중복됩니다.");
          setShowPopup(true);
          // setPopupMessage(error.response.data.error);
          // setShowPopup(true);
        } else {
          setPopupMessage("등록 중 서버오류가 발생했습니다.");
          setShowPopup(true);
        }
      }
    }
  }

  // 이메일발송
  // 아이디 확인 및 이메일 발송
  const handleForgotPassword = async () => {
    // const handleForgotPassword = async () => {
    // event.preventDefault();

    try {
      // 아이디로 이메일 조회 ${clientConfig.URL}
      const responseEmail = await axios.post(`${clientConfig.URL}/getMasterEmail`, {
        uLoginId: addUser.caLoginId,
      });
      // alert();
      const { success, uEmail } = responseEmail.data;
      // console.log('responseEmail.data');
      // console.log(responseEmail.data);
      if (success) {
        // 이메일 전송
        const response = await axios.post(`${clientConfig.URL}/sendMasterEmail`, {
          toEmail: addUser.caEmail, // 조회된 이메일로 전송
          subject: '임시 비밀번호 발급',
          text: '임시 비밀번호를 발급합니다.',
          uLoginId: addUser.caLoginId
        });

        if (response.data.success) {
          // 팝업 메시지 설정
          setPopupMessage(`이메일 주소: ${uEmail}\n이메일이 성공적으로 보내졌습니다.`);
        } else {
          // 팝업 메시지 설정
          setPopupMessage(`이메일 주소: ${uEmail}\n이메일 보내기 실패: ${response.data.message}`);
        }
      } else {
        // 팝업 메시지 설정
        setPopupMessage("이메일를 확인해주세요.");
      }

      // 팝업 열기
      setShowPopup(true);
    } catch (error) {
      // sendLog('Error handleForgotPassword /getEmail', `Error details : ${error}`, uLoginId, getFileName);
      console.error('이메일 조회 오류:', error);
      // 팝업 메시지 설정
      setPopupMessage("서버 오류 관리자에게 문의해 주세요.");
      // 팝업 열기
      setShowPopup(true);
    }
  };


  return (
    <div>
      {/* 팝업 창 표시 상태가 true일 때 Popup 컴포넌트 렌더링 */}
      {showPopup && <Popup message={popupMessage} onClose={closePopup} />}
      <header id="home">
        {/* <!-- Start Navigation --> */}
        <nav className="navbar navbar-default navbar-fixed dark bootsnav">
          {/* <!-- Start Top Search --> */}
          <div className="container_box"></div>
          <div className="container">
            <div className="row">
              {/* <div className={`top-search ${isSearchVisible ? 'visible' : 'hidden'}`}> */}
              <div className="top-search" style={{ display: isSearchVisible ? 'block' : 'none' }}>
                <div className="input-group">
                  <form action="#">
                    <input type="text" name="text" className="form-control" placeholder="서비스 및 라이선스 검색" />
                    <button type="submit">
                      <i className="fas fa-search"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- End Top Search --> */}

          <div className="container">

            {/* <!-- Start Atribute Navigation --> */}
            <div className="attr-nav">
              <ul>
                {/* <li className="search"><a style={{ cursor: 'pointer' }} onClick={handleClick} ><i className="fa fa-search"></i></a></li> */}
                {/* 검색창 */}
                <li className="search"><a style={{ cursor: 'pointer' }} onClick={toggleSearch} ><i className="fa fa-search"></i></a></li>
                {/* 위젯창 */}
                <li className="side-menu"><a style={{ cursor: 'pointer' }} onClick={toggleSideMenu}><i className="fa fa-bars"></i></a></li>
              </ul>
            </div>
            {/* <!-- End Atribute Navigation --> */}

            {/* <!-- Start Header Navigation --> */}
            <div className="navbar-header">
              <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                <i className="fa fa-bars"></i>
              </button>
              <a className="navbar-brand" href="/main">
                {/* <img src="assets/img/logo.png" className="logo_is" style={{ width: '213px', height: '50px' }} alt="Logo" /> */}
                <img src={`${clientConfig.URL}/image/logo.png`} className="logo_is" style={{ width: '213px', height: '50px' }} alt="Logo" />
              </a>
            </div>
            {/* <!-- End Header Navigation --> */}

            {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
            <div className="collapse navbar-collapse" id="navbar-menu">
              <ul className="nav navbar-nav navbar-right" data-in="#" data-out="#">
                <li className="dropdown">
                  <a style={{ cursor: 'pointer' }} className="dropdown-toggle" data-toggle="dropdown" >Product</a>
                  <ul className="dropdown-menu">
                    {/* <Button>Browse</Button> */}
                    {menuData.map(item => <li><a href={item.msUrl} target={item.msTarget} key={item.pdId}>{item.msName}</a></li>)}
                  </ul>
                </li>

                <li className="dropdown">
                  <a style={{ cursor: 'pointer' }} className="dropdown-toggle" data-toggle="dropdown" >고객센터</a>
                  <ul className="dropdown-menu">
                    {menuData2.map(item => <li><a href={item.msUrl} key={item.pdId}>{item.msName}</a></li>)}

                  </ul>
                </li>

                <li>

                  {/* @ts-ignore */}
                  {/* {sessionStorage.getItem('isLoggedIn') === 'true' ? ( */}
                  {sessionStorage.getItem('isMasterLoggedIn') === 'true' || sessionStorage.getItem('isLoggedIn') === 'true' ? (
                    <a href="login" onClick={handleLogout}>Logout</a>
                  ) : (
                    <a href="login">Login</a>
                  )}
                </li>
                <li className="dropdown">
                  <a style={{ cursor: 'pointer' }} className="dropdown-toggle" data-toggle="dropdown">
                    내 메뉴
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a href="user_license">마이 페이지</a>
                    </li>
                    {/* <li>
                  <a href="admin_license_user_update">내 정보 수정</a>
                </li> */}
                    <li>
                      <a href="master_license_management">
                        솔루션 관리자
                      </a>
                    </li>
                    <li>
                      <a href="admin_license">파트너 관리자</a>
                    </li>
                    {/* <li>
                  <a href="user_license">파트너 일반사용자</a>
                </li> */}
                    {/* <li>
                  <a href="index">로그아웃</a>
                </li> */}
                  </ul>
                </li>
              </ul>
            </div>
            {/* <!-- /.navbar-collapse --> */}
          </div>
          <div className={`side ${isSideMenuVisible ? 'on' : ''}`} >
            {/* 위젯창 닫기 버튼 */}
            <a style={{ cursor: 'pointer' }} className="close-side" onClick={closeSideMenu}><i className="fa fa-times"></i></a>
            {companyInfo2 ? (
            <div className="widget">
              <h4 className="title_widget">솔루션 관리자 정보 수정</h4>
              <div className="widget_user_resist_area">
                <div className="widget_user_resist_left">
                  {/* <div className="widget_user_resist_left_label">ID :</div> */}
                  <div className="widget_user_resist_left_label">Name :</div>
                  <div className="widget_user_resist_left_label">E-mail :</div>
                  <div className="widget_user_resist_left_label">Phone :</div>
                  <div className="widget_user_resist_left_label">부서 :</div>
                  <div className="widget_user_resist_left_label">직급 :</div>
                </div>
                <div className="widget_user_resist_right">

                  {/* <div className="widget_user_resist_right_label">
                    <input type="text" className="widget_user_resist_input"
                      value={editUser?.caLoginId || ''}
                      onChange={(e) => handleChange(e, 'caLoginId')}
                    />
                  </div> */}
                  <div className="widget_user_resist_right_label">
                    <input type="text" className="widget_user_resist_input"
                      value={editUser?.caName ?? ''}
                      onChange={(e) => handleChange(e, 'caName')}
                      placeholder='이름'
                      maxLength={50}
                    />
                  </div>
                  <div className="widget_user_resist_right_label">
                    <input type="text" className="widget_user_resist_input"
                    value={editUser?.caEmail ?? ''}
                    onChange={(e) => handleChange(e, 'caEmail')}
                    placeholder='이메일'
                    maxLength={500}
                    />
                  </div>
                  <div className="widget_user_resist_right_label">
                    <input type="text" className="widget_user_resist_input"
                    value={editUser?.caPhone ?? ''}
                    onChange={(e) => handleChange(e, 'caPhone')}
                    placeholder='전화번호'
                    maxLength={50}
                    />
                  </div>
                  <div className="widget_user_resist_right_label">
                    <input type="text" className="widget_user_resist_input"
                    value={editUser?.caDept ?? ''}
                    onChange={(e) => handleChange(e, 'caDept')}
                    placeholder='부서'
                    maxLength={50}
                    />
                  </div>
                  <div className="widget_user_resist_right_label">
                    <input type="text" className="widget_user_resist_input"
                    value={editUser?.caRank ?? ''}
                    onChange={(e) => handleChange(e, 'caRank')}
                    placeholder='직급'
                    maxLength={50}
                    />
                  </div>
                </div>
              </div>


              {/* <div className="widget_user_resist_area_bl">
                <div className="widget_user_resist_left_label_bl">
                  ID : {companyInfo2?.caLoginId || ''}
                </div>
                <div className="widget_user_resist_left_label_bl">
                  <input
                    type="text"
                    placeholder="수정할 ID를 입력해주세요"
                    className="widget_user_resist_input_bl"
                    value={editUser?.caLoginId || ''}
                    onChange={(e) => handleChange(e, 'caLoginId')}
                  />
                </div>
                <div className="widget_user_resist_left_label_bl">
                  Name : {companyInfo2?.caName || ''}
                </div>
                <div className="widget_user_resist_left_label_bl">
                  <input
                    type="text"
                    placeholder="수정할 이름을 입력해주세요"
                    className="widget_user_resist_input_bl"
                    value={editUser?.caName ?? ''}
                    onChange={(e) => handleChange(e, 'caName')}
                  />
                </div>
                <div className="widget_user_resist_left_label_bl">
                  E-mail : {companyInfo2?.caEmail || ''}
                </div>
                <div className="widget_user_resist_left_label_bl">
                  <input
                    type="text"
                    placeholder="수정할 E-mail을 입력해주세요"
                    className="widget_user_resist_input_bl"
                    value={editUser?.caEmail ?? ''}
                    onChange={(e) => handleChange(e, 'caEmail')}
                  />
                </div>
                <div className="widget_user_resist_left_label_bl">
                  Phone : {companyInfo2?.caPhone || ''}
                </div>
                <div className="widget_user_resist_left_label_bl">
                  <input
                    type="text"
                    placeholder="수정할 E-mail을 입력해주세요"
                    className="widget_user_resist_input_bl"
                    value={editUser?.caPhone ?? ''}
                    onChange={(e) => handleChange(e, 'caPhone')}
                  />
                </div>
                <div className="widget_user_resist_left_label_bl">
                  부서 : {companyInfo2?.caDept || ''}
                </div>
                <div className="widget_user_resist_left_label_bl">
                  <input
                    type="text"
                    placeholder="수정할 부서를 입력해주세요"
                    className="widget_user_resist_input_bl"
                    value={editUser?.caDept ?? ''}
                    onChange={(e) => handleChange(e, 'caDept')}
                  />
                </div>
                <div className="widget_user_resist_left_label_bl">
                  직급 : {companyInfo2?.caRank || ''}
                </div>
                <div className="widget_user_resist_left_label_bl">
                  <input
                    type="text"
                    placeholder="수정할 직급을 입력해주세요"
                    className="widget_user_resist_input_bl"
                    value={editUser?.caRank ?? ''}
                    onChange={(e) => handleChange(e, 'caRank')}
                  />
                </div>
              </div> */}
              <div className="widget_user_resist_area_bt">
                <a
                  className="btn btn-theme border btn-sm"
                  data-animation="animated slideInUp"
                  onClick={handelUpdateUserInfo}
                >
                  정보 수정
                </a>
              </div>
            </div>
            ):(
            <div className="widget">
              <h4 className="title_widget">솔루션 관리자 등록 하기</h4>
              <div className="widget_user_resist_area">
                <div className="widget_user_resist_left">
                  <div className="widget_user_resist_left_label">ID :</div>
                  <div className="widget_user_resist_left_label">Name :</div>
                  <div className="widget_user_resist_left_label">E-mail :</div>
                  <div className="widget_user_resist_left_label">Phone :</div>
                  <div className="widget_user_resist_left_label">부서 :</div>
                  <div className="widget_user_resist_left_label">직급 :</div>
                  {/* <div className="widget_user_resist_left_label">권한 :</div> */}
                  {/* <div class="widget_user_resist_left_label">PW :</div> */}
                </div>
                <div className="widget_user_resist_right">

                  <div className="widget_user_resist_right_label">
                    <input type="text" className="widget_user_resist_input"
                      onChange={(e) => handleUserAdd(e, 'caLoginId')}
                      value={addUser.caLoginId}
                      placeholder='아이디는 영문/숫자포함 20자'
                      maxLength={20}
                    />
                  </div>
                  <div className="widget_user_resist_right_label">
                    <input type="text" className="widget_user_resist_input"
                      onChange={(e) => handleUserAdd(e, 'caName')}
                      value={addUser.caName}
                      placeholder='이름'
                      maxLength={50}
                    />
                  </div>
                  <div className="widget_user_resist_right_label">
                    <input type="text" className="widget_user_resist_input"
                      onChange={(e) => handleUserAdd(e, 'caEmail')}
                      value={addUser.caEmail}
                      placeholder='이메일'
                      maxLength={500}
                    />
                  </div>
                  <div className="widget_user_resist_right_label">
                    <input type="text" className="widget_user_resist_input"
                      onChange={(e) => handleUserAdd(e, 'caPhone')}
                      value={addUser.caPhone}
                      placeholder='전화번호'
                      maxLength={50}
                    />
                  </div>
                  <div className="widget_user_resist_right_label">
                    <input type="text" className="widget_user_resist_input"
                      onChange={(e) => handleUserAdd(e, 'caDept')}
                      value={addUser.caDept}
                      placeholder='부서'
                      maxLength={50}
                    />
                  </div>
                  <div className="widget_user_resist_right_label">
                    <input type="text" className="widget_user_resist_input"
                      onChange={(e) => handleUserAdd(e, 'caRank')}
                      value={addUser.caRank}
                      placeholder='직급'
                      maxLength={50}
                    />
                  </div>
                  {/* <div className="widget_user_resist_right_label">
                    <input type="text" className="widget_user_resist_input" />
                  </div> */}
                  {/* <div class="widget_user_resist_right_label"><input type="password" class="widget_user_resist_input"></div> */}
                </div>
              </div>
              <div className="widget_user_resist_area_bt">
                <a
                  className="btn btn-theme border btn-sm"
                  data-animation="animated slideInUp"
                  onClick={handelInsertUserInfo}
                >
                  사용자 등록
                </a>
              </div>
            </div>
            )}
          </div>

        </nav>

      </header>
    </div>
  )
}

export default MasterSolutionHeader